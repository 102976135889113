import type { SVGProps } from 'react';

export default function SvgSk(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35}
      height={35}
      fill="none"
      viewBox="0 0 130 80"
      {...props}
    >
      <g filter="url(#sk_svg__a)">
        <g clipPath="url(#sk_svg__b)">
          <rect
            width={117.6}
            height={84}
            x={7.5}
            y={3}
            fill="#1A47B8"
            rx={9.143}
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M7.5 59h117.6v28H7.5z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M7.5 3h117.6v28H7.5zm55.608 56h.392V31H24.3v28h.392a10.3 10.3 0 0 0-.392 2.8c0 7.728 19.6 14 19.6 14s19.6-6.272 19.6-14c0-.952-.134-1.893-.392-2.8"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M57.9 59V25.4h-28V59c0 6.188 14 11.2 14 11.2s14-5.012 14-11.2"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M41.1 47.8v-5.6h-5.6v-5.6h5.6V31h5.6v5.6h5.6v5.6h-5.6v5.6h8.4v5.6h-8.4V59h-5.6v-5.6h-8.4v-5.6z"
            clipRule="evenodd"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M43.9 70.2s14-5.012 14-11.2-5.6-5.6-14 0c-8.4-5.6-14-6.188-14 0s14 11.2 14 11.2"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="sk_svg__b">
          <rect
            width={117.6}
            height={84}
            x={7.5}
            y={3}
            fill="#fff"
            rx={9.143}
          />
        </clipPath>
        <filter
          id="sk_svg__a"
          width={131.314}
          height={97.714}
          x={0.643}
          y={0.714}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4.571} />
          <feGaussianBlur stdDeviation={3.429} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1055_17641"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={0.286} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.32 0" />
          <feBlend
            in2="effect1_dropShadow_1055_17641"
            result="effect2_dropShadow_1055_17641"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1055_17641"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
