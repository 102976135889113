import { buttonVariants } from '@/components/base/button';
import { PageHeading } from '@/components/base/page-heading';
import { ScreenContainer } from '@/components/base/screen-container';
import { cn } from '@/utils';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva, type VariantProps } from 'class-variance-authority';
import { Trans, useTranslation } from 'react-i18next';

const programs = [
  {
    title: 'affiliatePage.card1.title',
    amount: '10%',
    subtitle: 'affiliatePage.card1.description',
    items: ['affiliatePage.card1.check1', 'affiliatePage.card1.check2'],
    type: 'Bronze',
  },
  {
    title: 'affiliatePage.card2.title',
    amount: '15%',
    subtitle: 'affiliatePage.card2.description',
    items: ['affiliatePage.card2.check2', 'affiliatePage.card2.check3'],
    extraItems: ['affiliatePage.card2.check1'],
    type: 'Silver',
    customersNeeded: 50,
  },
  {
    title: 'affiliatePage.card3.title',
    amount: '20%',
    subtitle: 'affiliatePage.card3.description',
    items: [
      'affiliatePage.card3.check2',
      'affiliatePage.card3.check3',
      'affiliatePage.card3.check4',
    ],
    extraItems: ['affiliatePage.card3.check1', 'affiliatePage.card3.check5'],
    type: 'Gold',
    customersNeeded: 100,
  },
];

const programCardVariants = cva(
  'relative w-full lg:min-h-24 min-h-20 bg-gradient-to-t dark:to-[#1D0C0C] border-t-[#FF0000] dark:p-px p-1 rounded-xl',
  {
    variants: {
      variant: {
        bronze: 'from-[#CD510A]',
        silver: 'from-[#DEDEDE]',
        gold: 'from-[#D89A01]',
      },
    },
    defaultVariants: {
      variant: 'silver',
    },
  },
);

const programTextVariants = cva(
  'bg-gradient-to-r to-transparent text-transparent bg-clip-text',
  {
    variants: {
      variant: {
        bronze: 'from-[#ad4b14] via-[#ad4b14]',
        silver: 'dark:from-[#DDDDDD] dark:via-[#DDDDDD] from-[#1D0C0C]',
        gold: 'from-[#D89A01] via-[#D89A01]',
      },
    },
    defaultVariants: {
      variant: 'silver',
    },
  },
);

export interface ProgramCardProps
  extends VariantProps<typeof programCardVariants> {
  title: string;
  amount: string;
  subtitle: string;
  items: string[];
  type: string;
  customersNeeded?: number;
  extraItems?: string[];
}

function ProgramCard({
  title,
  amount,
  subtitle,
  type,
  items,
  variant,
  customersNeeded,
  extraItems,
}: ProgramCardProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={cn(programCardVariants({ variant }))}>
      <div className="flex h-fit flex-col gap-6 rounded-xl bg-default-gray-100 p-8 dark:bg-[#161618]">
        <div className="flex items-center justify-end">
          {customersNeeded ? (
            <span className="w-fit rounded-full border border-default-gray-500 px-2 py-px text-sm text-default-gray-950 dark:border-white dark:text-white">
              <Trans
                i18nKey="affiliatePage.affiliateCustomers"
                values={{ value: customersNeeded }}
              />
            </span>
          ) : null}
        </div>
        <div className="flex flex-col gap-3 divide-y divide-[#5A5A5A]">
          <div className="flex flex-col gap-5 text-center">
            <span className={cn(programTextVariants({ variant }))}>{type}</span>

            <span
              className={cn(
                'text-7xl font-bold',
                cn(programTextVariants({ variant })),
              )}
            >
              {amount}
            </span>

            <PageHeading
              size="small"
              className="lg:text-center"
              subheading={t(subtitle)}
            >
              {t(title)}
            </PageHeading>
          </div>
          <ul className="pt-3">
            {items.map((item) => (
              <li key={item} className="flex items-center justify-start gap-3">
                <FontAwesomeIcon
                  className="text-default-green"
                  icon={faCheck}
                  height={12}
                  width={12}
                />
                <span className="text-default-gray-950 dark:text-white">
                  {t(item)}
                </span>
              </li>
            ))}
          </ul>
          {extraItems ? (
            <ul className="pt-3 text-default-green">
              {extraItems.map((item) => (
                <li
                  key={item}
                  className="flex items-center justify-start gap-3"
                >
                  <FontAwesomeIcon icon={faCheck} height={12} width={12} />
                  <span>{t(item)}</span>
                </li>
              ))}
            </ul>
          ) : null}
        </div>

        <a
          href="https://affiliates.monevis.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={buttonVariants({
            variant: 'default',
          })}
        >
          {t('affiliatePage.ctaButton')}
        </a>
      </div>
    </div>
  );
}

export function AffiliateProgram(): JSX.Element {
  const getVariant = (i: number) => {
    switch (i) {
      case 0:
        return 'bronze';
      case 1:
        return 'silver';
      case 2:
        return 'gold';
      default:
        return 'silver';
    }
  };

  return (
    <ScreenContainer className="mb-16 h-fit">
      <div className="flex flex-col items-end gap-8 xl:flex-row">
        {programs.map((program, i) => (
          <ProgramCard
            type={program.type}
            variant={getVariant(i)}
            key={program.title}
            title={program.title}
            subtitle={program.subtitle}
            amount={program.amount}
            items={program.items}
            customersNeeded={program.customersNeeded}
            extraItems={program.extraItems}
          />
        ))}
      </div>
    </ScreenContainer>
  );
}
