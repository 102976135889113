import { Badge } from '@/components/base/badge';
import { Button } from '@/components/base/button';
import { Skeleton } from '@/components/base/skeleton';
import { usePageLayout } from '@/context/layout-context';
import { badgeTitleMap, iconVariantMap } from '@/utils/challenge-config';
import {
  faCheck,
  faCreditCard,
  faSquareTerminal,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AccountType,
  ChallengeStatus,
  useGetBrokerAccountQuery,
  useGetBrokerAccountStatisticsQuery,
  type BrokerAccountFragment,
  type MonevisAnalysisResultOutcome,
} from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { AccountSelectlistWithData } from './accounts-selectlist';
import { CredentialsModalWithData } from './credentials-modal';

export function BrokerAccountDetails({
  brokerAccount,
  outcome,
  accountId,
}: {
  brokerAccount: BrokerAccountFragment;
  outcome: MonevisAnalysisResultOutcome;
  accountId: string;
}): JSX.Element {
  const { t } = useTranslation();
  const isFunded = brokerAccount.accountType === AccountType.Funded;
  return (
    <div className="default-gray-900 flex w-full flex-col justify-between gap-3 dark:text-white md:flex-row">
      <div className="flex h-full flex-col gap-3 md:flex-row">
        <AccountSelectlistWithData
          outcome={outcome}
          accountId={accountId}
          className="w-full md:w-fit"
        />

        <div className="col-span-2 flex w-full flex-wrap items-stretch justify-start gap-2 md:justify-start">
          <Badge
            variant={iconVariantMap[outcome].badgeVariant}
            iconLeft={iconVariantMap[outcome].badgeIcon}
            className="px-3 py-1"
          >
            {badgeTitleMap[brokerAccount.accountType]}
          </Badge>
          <Badge className="px-3 py-1">{t('accountDetail.metatrader')}</Badge>
          <Badge className="px-3 py-1">1:{brokerAccount.leverage}</Badge>
          <Badge
            iconRightClassName="text-green-500"
            iconRight={faCheck}
            className="px-3 py-1"
          >
            {t('accountDetail.eas')}
          </Badge>
          {!isFunded && (
            <Badge
              iconRightClassName="text-green-500"
              iconRight={faCheck}
              className="px-3 py-1"
            >
              {t('accountDetail.news')}
            </Badge>
          )}
        </div>
      </div>
      <div className="flex w-full flex-wrap justify-end gap-3 md:w-fit">
        <CredentialsModalWithData accountId={accountId} />
        <Link
          to={`/dashboard/accounts/terminal/${accountId}`}
          params={{ 'account-id': accountId }}
          className="h-fit w-full flex-1 font-bold"
        >
          <Button
            icon={<FontAwesomeIcon icon={faSquareTerminal} />}
            className="w-full font-bold"
            variant="gray"
          >
            {t('accountDetail.terminal')}
          </Button>
        </Link>
        {brokerAccount.accountType === AccountType.Funded &&
          brokerAccount.challengeStatus !== ChallengeStatus.Failed && (
            <Link
              to="/dashboard/request-payout"
              search={{
                login: accountId,
              }}
              className="w-full md:w-fit"
            >
              <Button
                icon={<FontAwesomeIcon icon={faCreditCard} />}
                className="w-full font-bold"
                variant="gray"
              >
                {t('payout.requestButton')}
              </Button>
            </Link>
          )}
      </div>
    </div>
  );
}

export function BrokerAccountDetailsWithData({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { data: brokerAccount, loading: brokerAccountLoading } =
    useGetBrokerAccountQuery({
      variables: { id: accountId },
      fetchPolicy: 'network-only',
    });
  const { data: accountStats, loading: statsLoading } =
    useGetBrokerAccountStatisticsQuery({
      variables: { id: accountId },
      fetchPolicy: 'network-only',
    });

  const { t } = useTranslation();

  usePageLayout({
    title:
      brokerAccountLoading || statsLoading
        ? t('accountDetail.loadingTitle')
        : `${t('accountDetail.detailTitle')} - ${brokerAccount?.brokerAccount.login}`,
  });

  if (brokerAccountLoading || statsLoading) {
    return (
      <div className="flex flex-wrap gap-2">
        <Skeleton className="h-full min-h-8 w-full min-w-80" />
      </div>
    );
  }

  invariant(brokerAccount, `[${accountId}] Broker account missing`);
  invariant(
    accountStats?.brokerAccountStatistics.stats?.outcome,
    `[${accountId}] Analysis outcome missing`,
  );

  return (
    <BrokerAccountDetails
      brokerAccount={brokerAccount.brokerAccount}
      outcome={accountStats?.brokerAccountStatistics.stats?.outcome}
      accountId={accountId}
    />
  );
}
