import { useCurrentUser } from '@/context/current-user-context';
import { OrderContextProvider, useOrderContext } from '@/context/order-context';
import { isBannedCountry } from '@/utils/country-list';
import { isEuropeanUnion } from '@/utils/order-utils';
import {
  CartStatus,
  useGeolocationQuery,
  useRequestCartMutation,
  type AddonFragment,
  type CartFragment,
} from '@graphql/index';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { Button } from '../base/button';
import { AddDiscountCode } from './components/add-discount-code';
import { Info } from './components/info';
import { OrderFormWrapper, type Order } from './components/order-form-wrapper';
import { OrderSummary } from './components/order-summary';
import { SignInModal } from './components/sign-in-modal';
import { DiscountBanner } from './steps/discount-banner';
import { PersonalInfoForm } from './steps/personal-info-form';
import { SelectAccountSize } from './steps/select-account-size';
import { SelectAddons } from './steps/select-addons';

function OrderForm({
  promo,
  balance,
}: {
  promo?: string;
  balance?: number;
}): JSX.Element {
  const { handleSubmit, getValues, setValue, watch } = useFormContext<Order>();
  const watchCountry = watch('countryCode');
  const [showSignInModal, setShowSignInModal] = useState(false);
  const {
    cart,
    loading,
    setInitialBalance,
    toggleAddon,
    applyDiscount,
    placeOrder,
  } = useOrderContext();
  const user = useCurrentUser();
  invariant(cart, 'Cart must be defined');
  const { t } = useTranslation();

  const { data: geolocation } = useGeolocationQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (geolocation?.geolocation.country) {
      setValue('countryCode', geolocation.geolocation.country);
    }
    if (geolocation?.geolocation.city) {
      setValue('city', geolocation.geolocation.city);
    }

    if (geolocation?.geolocation.zipCode) {
      setValue('zip', geolocation.geolocation.zipCode);
    }
  }, [geolocation, setValue]);

  useEffect(() => {
    if (promo && !loading && cart) {
      applyDiscount(promo);
    }
    if (balance && !loading && cart) {
      setInitialBalance(balance);
    }
  }, [promo, balance, cart]);

  return (
    <>
      <SignInModal
        open={showSignInModal}
        setOpen={(value) => {
          setShowSignInModal(value);
        }}
      />
      <Info />
      <DiscountBanner cartId={cart.id!} />
      <SelectAccountSize
        country={String(geolocation?.geolocation.country)}
        finalPrice={cart.total}
        oldPrice={cart.subTotal}
        currency={isEuropeanUnion(getValues('countryCode')) ? 'EUR' : 'USD'}
        hardBalance={balance}
        onChange={(b) => setInitialBalance(b)}
        initialState={String(cart.initialBalance)}
      />
      <SelectAddons
        onSelect={(addon) => {
          toggleAddon(addon);
        }}
        currentAddons={(cart.addons as AddonFragment[]) ?? []}
      />
      {cart.discountType}
      <PersonalInfoForm
        watchCountry={watchCountry}
        isLoggedIn={Boolean(user)}
        geolocation={geolocation}
      />
      <AddDiscountCode handleAddDiscount={applyDiscount} cartId={cart.id!} />
      <DiscountBanner cartId={cart.id!} />
      {Number(cart.subTotal) > 0 && !isBannedCountry(watchCountry) ? (
        <OrderSummary
          loading={loading}
          total={cart.total}
          subTotal={cart.subTotal}
          currency={isEuropeanUnion(getValues('countryCode')) ? 'EUR' : 'USD'}
          onSubmit={(method) => {
            handleSubmit(async () => {
              const response = await placeOrder(method, getValues());
              if (response instanceof Error) {
                if (response.message === 'Unauthorized') {
                  setShowSignInModal(true);
                  // alert('login');
                }
              }
            })();
          }}
        />
      ) : (
        <div className="flex justify-center">
          <Button
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
            className="w-full max-w-96"
          >
            {t('orderSummary.pay')}
          </Button>
        </div>
      )}
    </>
  );
}

export function OrderPageWithCart({
  promo,
  balance,
}: {
  promo?: string;
  balance?: number;
}): JSX.Element {
  const { cart } = useOrderContext();

  invariant(cart, 'Cart must be defined');

  return (
    <div className="mx-auto w-full space-y-8">
      <OrderFormWrapper>
        <OrderForm promo={promo} balance={balance} />
      </OrderFormWrapper>
    </div>
  );
}

export function OrderPage({
  promo,
  balance,
}: {
  promo?: string;
  balance?: number;
}): JSX.Element {
  const [cookie, setCookie] = useCookies(['cartId']);
  const [cartId, setCartId] = useState<string>(String(cookie.cartId));
  const [requestCart] = useRequestCartMutation();

  async function requestEmptyCart(): Promise<CartFragment | null> {
    const newCart = await requestCart({
      variables: {
        cartId: null,
      },
    });
    if (newCart.data?.requestCart.id) {
      setCartId(newCart.data.requestCart.id);
      setCookie('cartId', newCart.data.requestCart.id, { path: '/' });
      return newCart.data.requestCart;
    }
    return null;
  }

  async function refetchCart(id: string): Promise<void> {
    if (!id || id === 'undefined' || id === undefined || id === null) {
      await requestEmptyCart();
    } else {
      try {
        const { data } = await requestCart({
          variables: {
            cartId: !id || id === 'undefined' ? null : id,
          },
        });
        if (
          data?.requestCart.id &&
          data.requestCart.status !== CartStatus.Completed
        ) {
          setCartId(data.requestCart.id);
          setCookie('cartId', data.requestCart.id, { path: '/' });
        } else {
          await requestEmptyCart();
        }
      } catch (e) {
        await requestEmptyCart();
      }
    }
  }

  useEffect(() => {
    void (async () => {
      await refetchCart(cartId);
    })();
  }, [cartId]);

  if (!cartId) {
    return <></>;
  }

  return (
    <OrderContextProvider cartId={cartId}>
      <OrderPageWithCart promo={promo} balance={balance} />
    </OrderContextProvider>
  );
}
