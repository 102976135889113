import { Button } from '@/components/base/button';
import { CountryPicker } from '@/components/base/form/country-picker';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import { TextField } from '@/components/base/form/text-field';
import { isBannedCountry } from '@/utils/country-list';
import { useGeolocationQuery } from '@graphql/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, type FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { TermsCheckbox } from '../../base/terms-checkbox';

const userSchema = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email(),
  password: z.string().min(6, { message: 'Password is required' }),
  firstName: z.string().min(1, { message: 'First Name is required' }),
  lastName: z.string().min(1, { message: 'Last Name is required' }),
  policy: z.boolean().refine((value) => value, {
    message: 'Policy acceptance is required',
  }),
  countryCode: z.string().min(1, { message: 'Country is required' }),
});

type User = z.infer<typeof userSchema>;

export function RegistrationForm({
  onSubmit,
  loading,
}: {
  onSubmit: (values: User) => Promise<void>;
  loading: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const methods = useForm<User>({
    resolver: zodResolver(userSchema),
    mode: 'onChange',
  });
  const { handleSubmit, setValue, watch } = methods;
  const watchCountry = watch('countryCode');

  const { data: geolocation, loading: geolocationLoading } =
    useGeolocationQuery({
      fetchPolicy: 'network-only',
    });
  useEffect(() => {
    if (geolocation?.geolocation.country) {
      setValue('countryCode', geolocation?.geolocation.country);
    }
  }, [geolocation, setValue]);

  function handleClickSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    return void (async () => {
      handleSubmit(onSubmit)();
    })();
  }
  const isGeoBanned = Boolean(
    geolocation?.geolocation.country && isBannedCountry(watchCountry),
  );
  const isGeo = geolocation?.geolocation.country && !geolocationLoading;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleClickSubmit} data-testid="register-form">
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-5 sm:flex-row">
              <fieldset className="w-full">
                <FormField label={t('registration.firstName')} name="firstName">
                  <Input
                    data-testid="first-name-input"
                    placeholder={t('registration.firstNamePlaceholder')}
                  />
                </FormField>
              </fieldset>
              <fieldset className="w-full">
                <FormField label={t('registration.lastName')} name="lastName">
                  <Input
                    data-testid="last-name-input"
                    placeholder={t('registration.lastNamePlaceholder')}
                  />
                </FormField>
              </fieldset>
            </div>
            <fieldset>
              <FormField label={t('registration.email')} name="email">
                <Input
                  data-testid="email-input"
                  placeholder={t('registration.emailPlaceholder')}
                />
              </FormField>
            </fieldset>
            <fieldset className="w-full">
              <FormField label={t('registration.password')} name="password">
                <Input
                  data-testid="password-input"
                  type="password"
                  placeholder="••••••"
                />
              </FormField>
            </fieldset>
          </div>
          {(!isGeo || isGeoBanned) && (
            <fieldset className="w-full">
              <TextField label={t('registration.country')}>
                <CountryPicker
                  disabled={Boolean(geolocation?.geolocation.country)}
                  data-testid="country-code-input"
                  name="countryCode"
                  defaultValue={watchCountry}
                />
              </TextField>
            </fieldset>
          )}
          <div>
            <TermsCheckbox
              name="policy"
              data-testid="terms-checkbox"
              description={t('registration.policyDescription')}
            />
          </div>
          <Button
            as="button"
            className="text-base font-bold"
            loading={loading}
            type="submit"
            data-testid="registration-submit-button"
            disabled={isBannedCountry(watchCountry)}
          >
            {t('registration.createAccount')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
