import { PageHeading } from '@/components/base/page-heading';
import { RadioGroup, RadioGroupItem } from '@/components/base/radio-group';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { RadioItemDecoration } from '../components/radio-item-decoration';
import { getTiers } from '../configuration';

interface SelectAccountsizeProps {
  initialState?: string;
  onChange: (value: number) => Promise<void>;
}

export function SelectTrialAccountSize({
  initialState,
  onChange = () => Promise.resolve(),
}: SelectAccountsizeProps): JSX.Element {
  const intl = useIntl();
  const key = initialState;
  const { t } = useTranslation();

  async function handleOnChange(value: number): Promise<void> {
    await onChange(value);
  }

  return (
    <div key={key}>
      <PageHeading
        size="xxlarge"
        subheading={t('selectTrialAccountSize.subheading')}
      >
        {t('selectTrialAccountSize.heading')}
      </PageHeading>

      <RadioGroup
        onValueChange={(v: string) => {
          onChange(Number(v));
        }}
        defaultValue={initialState}
        className="mb-8 mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3"
      >
        {getTiers().map((item) => {
          return (
            <RadioGroupItem
              onClick={async () => {
                await handleOnChange(item);
              }}
              decoration={
                <RadioItemDecoration
                  label={t('selectTrialAccountSize.freeLabel')}
                />
              }
              key={item}
              value={String(item)}
              data-testid={String(item)}
            >
              <span className="text-xl font-bold">
                {intl.formatNumber(item, {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0,
                })}
              </span>
            </RadioGroupItem>
          );
        })}
      </RadioGroup>
    </div>
  );
}
