import { AffiliateProgram } from '@/components/(authenticated)/affiliate/affiliate-program';
import { Certificates } from '@/components/(authenticated)/affiliate/certificates';
import { Intro } from '@/components/(authenticated)/affiliate/intro';
import { JoinSocialsSection } from '@/components/(authenticated)/calendar/help-section';
import { PageHeading } from '@/components/base/page-heading';
import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { usePageLayout } from '@/context/layout-context';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/affiliate',
)({
  component: () => (
    <PageWrapper title="Affiliate - Monevis">
      <Affiliate />
    </PageWrapper>
  ),
});

function Affiliate(): JSX.Element {
  const { t } = useTranslation();

  usePageLayout({ title: t('affiliatePage.title') });

  return (
    <ScreenContainer className="justify-between">
      <Intro />
      <AffiliateProgram />
      <Certificates />
      <JoinSocialsSection>
        <PageHeading subheading={t('resource.subheading')}>
          {t('resource.join')}
        </PageHeading>
      </JoinSocialsSection>
    </ScreenContainer>
  );
}

export default Affiliate;
