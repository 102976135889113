import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/base/table';
import {
  useGetBrokerAccountQuery,
  useGetClosedPositionsQuery,
  type AccountTradingPositionFragment,
} from '@graphql/index';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { defaultColumns } from './components/columns';

export enum Tab {
  ThisWeek = 'This Week',
}

export function ClosedPositionsTable({
  data,
  initialBalance,
}: {
  data: (AccountTradingPositionFragment | null)[];
  initialBalance: number;
}): JSX.Element {
  const intl = useIntl();
  const { t } = useTranslation();
  const columns = useMemo(() => defaultColumns(intl, { t }), [intl]);
  const positionsWithGain = useMemo(
    () =>
      data.map((position) => {
        invariant(position?.totalProfit !== null, 'Profit error');
        invariant(position?.fees !== null, 'Fees error');

        const gain = (position?.totalProfit ?? 0) - (position?.fees ?? 0);
        const gainPercentage = (gain / initialBalance) * 100;

        return {
          ...position,
          gainPercentage,
        };
      }),
    [data],
  );

  const table = useReactTable({
    data: positionsWithGain ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div>
      <Section className="p-0 sm:p-0" variant="outline">
        <Table data-testid="closed-positions-table">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                className="[&>*:first-child]:pl-5 [&>*:last-child]:pr-5"
                id={headerGroup.id}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className="w-[100px] px-1 md:px-5"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                className="text-xs text-default-gray-400 sm:text-sm"
                id={row.id}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell
                      className="whitespace-nowrap px-3 py-3 sm:px-6"
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {table.getRowModel().rows.length === 0 && (
              <TableRow className="border-none">
                <TableCell
                  colSpan={columns.length}
                  className="whitespace-nowrap px-3 py-3 sm:px-6"
                >
                  <div className="flex w-full items-center justify-center text-default-gray-950 dark:text-white">
                    <span>{t('calendar.noData')}</span>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Section>
    </div>
  );
}

export function ClosedPistionsTableWithData({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { data, loading } = useGetClosedPositionsQuery({
    variables: { login: accountId },
    fetchPolicy: 'cache-and-network',
  });
  const { data: brokerAccount, loading: brokerAccountLoading } =
    useGetBrokerAccountQuery({
      variables: { id: accountId },
      fetchPolicy: 'cache-and-network',
    });

  if (loading || brokerAccountLoading) {
    return (
      <div className="h-40 w-full">
        <Skeleton className="h-full w-full rounded-2xl" />
      </div>
    );
  }
  invariant(
    data?.closedPositions,
    `[${accountId}] Closed positions are missing`,
  );
  invariant(
    brokerAccount?.brokerAccount.initialBalance,
    `[${accountId}] Broker account is missing`,
  );

  const sortedPositions = [...data.closedPositions].sort((a, b) => {
    invariant(a?.dateClose, 'Date error');
    invariant(b?.dateClose, 'Date error');

    return Date.parse(b?.dateClose) - Date.parse(a?.dateClose);
  });

  return (
    <ClosedPositionsTable
      data={sortedPositions}
      initialBalance={brokerAccount.brokerAccount.initialBalance}
    />
  );
}
