export function RadioItemDecoration({
  label,
}: {
  label: string | JSX.Element;
}): JSX.Element {
  return (
    <div className="rounded-3xl border border-primary bg-primary/10 px-4 py-1 font-bold text-primary group-data-[state=checked]:border-emerald-400 group-data-[state=checked]:bg-emerald-400/10 group-data-[state=checked]:text-emerald-400 dark:text-white group-data-[state=checked]:dark:border-emerald-400 group-data-[state=checked]:dark:bg-emerald-400/10 group-data-[state=checked]:dark:text-white">
      {label}
    </div>
  );
}
