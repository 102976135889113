import { cn } from '@/utils';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface CheckboxProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  decoration?: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
}

export function CustomCheckbox({
  children,
  decoration,
  active,
  onClick,
  ...props
}: CheckboxProps): JSX.Element {
  return (
    <button
      {...props}
      onClick={onClick}
      type="button"
      className={cn(
        'relative flex h-full w-full cursor-pointer items-center justify-between gap-2 rounded-lg border border-default-gray-400 px-4 py-3 text-slate-900 ring-offset-white focus:border-green-400 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 dark:border-white dark:text-slate-50',
        {
          'border-emerald-400 bg-emerald-400/5 dark:border-emerald-400': active,
        },
      )}
    >
      <span
        className={cn('w-full text-start dark:text-slate-50', {
          'text-emerald-400 dark:text-emerald-400': active,
        })}
      >
        {children}
      </span>
      <div className="flex items-center gap-3">
        <div
          className={cn(
            'absolute right-1 top-1 hidden items-center justify-center',
            {
              'flex text-emerald-400': active,
            },
          )}
        >
          <FontAwesomeIcon className="text-xl" icon={faCircleCheck} />
        </div>
        {decoration}
      </div>
    </button>
  );
}
