import { buttonVariants } from '@/components/base/button';
import { PageHeading } from '@/components/base/page-heading';
import { ScreenContainer } from '@/components/base/screen-container';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CertificatesList } from './certificates-list';

interface CertificatesType {
  firstName: string;
  url: string;
  amount: number;
  fullUrl: string;
}

async function fetchCertificates(): Promise<CertificatesType[]> {
  try {
    const response = await fetch(
      'https://certificates.monevis.com/api/certificates?type=AFFILIATE_WITHDRAWAL&limit=16&upperLimit=16',
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status !== 200) {
      return [];
    }

    const certificates = (await response.json()) as {
      items: CertificatesType[];
    };

    return certificates.items;
  } catch (error) {
    return [];
  }
}

export function Certificates(): JSX.Element {
  const { t } = useTranslation();
  const [certificates, setCertificates] = useState<CertificatesType[]>([]);

  useEffect(() => {
    async function getCerts(): Promise<void> {
      const certificatesArr: CertificatesType[] = await fetchCertificates();
      setCertificates(certificatesArr);
    }
    getCerts();
  }, []);

  return (
    <ScreenContainer className="-mb-5">
      <div className="flex w-full flex-col items-center justify-center gap-8 text-center">
        <div className="max-w-[720px]">
          <PageHeading
            subheading={t('affiliatePage.affiliateCertificates.subtitle')}
          >
            {t('affiliatePage.affiliateCertificates.title')}
          </PageHeading>
        </div>

        <CertificatesList certificates={certificates} />

        <div className="flex -translate-y-14 gap-3">
          <a
            href="https://certificates.monevis.com/?type=AFFILIATE_WITHDRAWAL&limit=12"
            target="_blank"
            rel="noopener noreferrer"
            className={buttonVariants({
              variant: 'secondary',
            })}
          >
            {t('affiliatePage.affiliateCertificates.ctaButton1')}
          </a>

          <a
            href="https://affiliates.monevis.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={buttonVariants({
              variant: 'default',
            })}
          >
            {t('affiliatePage.affiliateCertificates.ctaButton2')}
          </a>
        </div>
      </div>
    </ScreenContainer>
  );
}
