import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import Section from '@/components/base/section';
import { OrderPage } from '@/components/order/order';
import { usePageLayout } from '@/context/layout-context';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/(global)/order/_layout/')({
  component: () => (
    <PageWrapper title="Order - Monevis">
      <Order />
    </PageWrapper>
  ),
  validateSearch: (
    search: Record<string, unknown>,
  ): { promo?: string; ref?: string; balance?: string } => {
    return {
      ref: search.ref ? String(search.ref) : undefined,
      promo: search.promo ? String(search.promo) : undefined,
      balance: search.balance ? String(search.balance) : undefined,
    };
  },
});

function Order(): JSX.Element {
  const { promo, balance } = Route.useSearch();
  const { t } = useTranslation();
  usePageLayout({ title: t('orderPage.title') });
  const balanceToNumber = balance ? parseFloat(balance) : undefined;

  return (
    <ScreenContainer>
      <Section variant="secondary">
        <OrderPage promo={promo} balance={balanceToNumber} />
      </Section>
    </ScreenContainer>
  );
}

export default Order;
