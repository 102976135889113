import * as Sentry from '@sentry/react';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { SnackbarProvider } from 'notistack';
import {
  Component,
  StrictMode,
  Suspense,
  useCallback,
  useEffect,
  type ErrorInfo,
  type ReactNode,
} from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { IntlProvider } from 'react-intl';
import { IntercomProvider } from 'react-use-intercom';
import '../tailwind.css';
import { AuthProvider, useAuth } from './auth';
import { ErrorPage } from './components/error-page';
import { GlobalScripts } from './components/global-scripts';
import {
  AnalyticsContextProvider,
  useAnalytics,
} from './context/analytics-context';
import { ApolloContextProvider } from './context/apollo-context';
import { AppConfigProvider } from './context/app-config-context';
import { CredentialsProvider } from './context/credentials-context';
import { PageLayoutProvider } from './context/layout-context';
import { routeTree } from './routeTree.gen';
import i18n from './utils/i18n';

Sentry.init({
  dsn: 'https://510877702eb93f982d589a16caf76779@o4506506499325952.ingest.us.sentry.io/4506972464414720',
  enabled: process.env.NODE_ENV === 'production',
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
}

class SentryErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error });

    Sentry.captureException(error, {
      extra: { errorInfo },
    });
  }

  render(): ReactNode {
    if (this.state.error) {
      // eslint-disable-next-line i18next/no-literal-string -- ok
      return <h1>Something went wrong!!</h1>;
    }

    return this.props.children;
  }
}

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => <ErrorPage className="px-4 py-8 sm:px-10" />,
  context: {
    auth: {
      isAuthenticated: true,
      loading: false,
      data: undefined,
    },
  },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function InnerApp(): JSX.Element {
  const auth = useAuth();
  const { page } = useAnalytics();

  const trackPage = useCallback(
    (innerRouter: typeof router): void => {
      const url = innerRouter.history.location.href;
      page({
        url,
      });
    },
    [page],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    trackPage(router);
    router.invalidate();
  }, [trackPage, auth]);

  router.history.subscribe(() => {
    trackPage(router);
  });

  return <RouterProvider router={router} context={{ auth }} />;
}

function App(): JSX.Element {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SentryErrorBoundary>
        <AnalyticsContextProvider>
          <I18nextProvider i18n={i18n}>
            <IntlProvider defaultLocale="en" locale="en">
              <CredentialsProvider>
                <SnackbarProvider
                  anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                  autoHideDuration={3000}
                >
                  <ApolloContextProvider>
                    <PageLayoutProvider>
                      <IntercomProvider appId="nm7pu0na" autoBoot>
                        <GlobalScripts />
                        <AppConfigProvider>
                          <AuthProvider>
                            <InnerApp />
                          </AuthProvider>
                        </AppConfigProvider>
                      </IntercomProvider>
                    </PageLayoutProvider>
                  </ApolloContextProvider>
                </SnackbarProvider>
              </CredentialsProvider>
            </IntlProvider>
          </I18nextProvider>
        </AnalyticsContextProvider>
      </SentryErrorBoundary>
    </Suspense>
  );
}

const rootElement = document.getElementById('root');
if (rootElement && !rootElement.innerHTML) {
  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
