interface CertificatesType {
  firstName: string;
  url: string;
  amount: number;
  fullUrl: string;
}

export function CertificatesList({
  certificates,
}: {
  certificates: CertificatesType[];
}): JSX.Element {
  return (
    <div className="relative grid grid-cols-4 justify-items-center gap-3 sm:gap-6 lg:grid-cols-8">
      {certificates.map((item, idx) => (
        <a
          key={item.firstName + idx}
          href={item.fullUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="group relative"
        >
          <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-300 group-hover:opacity-30" />
          <img
            src={`${item.url}?v=2`}
            alt={item.firstName}
            width={140}
            height={140}
          />
        </a>
      ))}
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-3/4 bg-gradient-to-t from-dark" />
    </div>
  );
}
