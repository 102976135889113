import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import { cn } from '@/utils';
import i18n from '@/utils/i18n';
import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type MonevisAccountTradingDiaryFragment,
  useGetAccountTradingDiaryQuery,
  useGetBrokerAccountQuery,
} from '@graphql/index';
import {
  addDays,
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  isSameDay,
  parseISO,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import * as locales from 'date-fns/locale';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { DetailDrawer } from './components/detail-drawer';

export function Calendar({
  diary,
  initialBalance,
  createdAt,
}: {
  diary: (MonevisAccountTradingDiaryFragment | null)[];
  initialBalance: number;
  createdAt: string;
}): JSX.Element {
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(today);
  const { t } = useTranslation();
  const intl = useIntl();

  const currentMonth = format(currentDate, 'M');
  const currentYear = format(currentDate, 'yyyy');
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const calendarStart = startOfWeek(monthStart, { weekStartsOn: 1 });
  const calendarEnd = endOfWeek(monthEnd, { weekStartsOn: 1 });
  const calendarDays = eachDayOfInterval({
    start: calendarStart,
    end: calendarEnd,
  });
  const hideNextArrow =
    Number(currentMonth) - 1 === today.getMonth() &&
    Number(currentYear) === today.getFullYear();

  const dataMap = useMemo(() => {
    return diary.reduce<
      Record<
        string,
        (MonevisAccountTradingDiaryFragment & { gainPercentage: number }) | null
      >
    >((map, item) => {
      if (item) {
        const dateKey = format(
          new Date(Number(item.year), Number(item.month) - 1, Number(item.day)),
          'yyyy-MM-dd',
        );
        const gainPercentage = item.endingBalance
          ? (item.endingBalance - initialBalance) / initialBalance
          : 0;
        if (item.endingBalance !== null && item.endingEquity !== null) {
          map[dateKey] = { ...item, gainPercentage };
        }
      }
      return map;
    }, {});
  }, [diary, currentYear, currentMonth]);

  function handlePrevMonth(): void {
    setCurrentDate(subMonths(currentDate, 1));
  }

  function handleNextMonth(): void {
    setCurrentDate(addMonths(currentDate, 1));
  }

  function getWeekdays(): string[] {
    const startWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const weekdays = [];

    for (let i = 0; i < 7; i++) {
      const weekday = format(addDays(startWeek, i), 'E', {
        locale: (locales as Record<string, locales.Locale | undefined>)[
          i18n.language
        ],
      });
      weekdays.push(
        intl.formatMessage({ id: weekday, defaultMessage: weekday }),
      );
    }
    return weekdays;
  }

  return (
    <Section variant="secondary">
      <div className="w-full text-default-gray-900 dark:text-white">
        <div className="mb-2 flex w-full justify-between gap-4 py-2">
          <div className="flex gap-4">
            <div className="flex items-center gap-1">
              <FontAwesomeIcon
                className="h-4 cursor-pointer"
                icon={faCircleArrowLeft}
                onClick={handlePrevMonth}
              />
              <span>{format(currentDate, 'MMMM')}</span>
              <span>{currentYear}</span>
              {!hideNextArrow && (
                <FontAwesomeIcon
                  className="h-4 cursor-pointer"
                  icon={faCircleArrowRight}
                  onClick={handleNextMonth}
                />
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-7 divide-default-gray-100 border-default-gray-100 dark:divide-default-gray-800 dark:border-default-gray-800 md:divide-x md:divide-y md:border-y md:border-r">
          <div className="col-span-7 grid grid-cols-7 divide-default-gray-100 border-default-gray-100 text-sm dark:divide-default-gray-800 dark:border-default-gray-800 md:divide-x md:border-l">
            {getWeekdays().map((weekday) => (
              <div key={weekday} className="p-2 text-center font-medium">
                {weekday}
              </div>
            ))}
          </div>
          {calendarDays.map((date) => {
            const formattedDate = format(date, 'yyyy-MM-dd');
            const diaryData = dataMap[formattedDate];
            const isToday = isSameDay(date, today);
            const isInThisMonth = date.getMonth() === currentDate.getMonth();
            const profit = diaryData
              ? (diaryData.endingBalance ?? 0) -
                (diaryData.startingBalance ?? 0)
              : 0;
            const hasTrades =
              diaryData &&
              (Boolean(diaryData.tradesExecuted) || Math.abs(profit) > 0);

            const createdAtDate = parseISO(createdAt);

            const objectDate = new Date(
              parseInt(diaryData?.year?.toString() ?? '0', 10),
              parseInt(diaryData?.month?.toString() ?? '0', 10) - 1,
              parseInt(diaryData?.day?.toString() ?? '0', 10) + 1,
            );
            const isOnOrAfter = !isBefore(objectDate, createdAtDate);
            return (
              <DetailDrawer
                profit={profit}
                lots={diaryData?.lotsExecuted ?? 0}
                trades={diaryData?.tradesExecuted ?? 0}
                key={formattedDate}
                gainPercentage={diaryData?.gainPercentage ?? 0}
              >
                <div
                  className={cn(
                    'flex h-14 w-full flex-col justify-between p-2 text-default-gray-700 dark:bg-default-gray-950 md:h-28',
                    {
                      'bg-default-gray-50 text-default-gray-300 dark:bg-default-gray-500/5':
                        !isInThisMonth,
                    },
                    {
                      'bg-danger/10 dark:bg-danger/20':
                        profit && profit < 0 && hasTrades && isInThisMonth,
                    },
                    {
                      'bg-default-green-700/10 dark:bg-default-green-700/20':
                        profit && profit > 0 && hasTrades && isInThisMonth,
                    },
                    {
                      'bg-default-gray-300/20 dark:bg-default-gray-500/20':
                        !hasTrades && isOnOrAfter && isInThisMonth,
                    },
                  )}
                >
                  <div className="flex w-full justify-center text-sm md:justify-end">
                    <span
                      className={cn(
                        'flex h-6 min-w-6 items-center justify-center text-default-gray-400 dark:text-default-gray-300',
                        {
                          'rounded-full bg-black font-medium text-white dark:bg-white dark:text-default-gray-950':
                            isToday,
                        },
                      )}
                    >
                      {format(date, 'd')}
                    </span>
                  </div>
                  {!hasTrades && isOnOrAfter && isInThisMonth && (
                    <span className="text-xs">
                      {t('accountDiary.noTrades')}
                    </span>
                  )}
                  {hasTrades && (
                    <div className="hidden flex-col text-default-gray-400 dark:text-white md:flex">
                      <span
                        className={cn(
                          'self-end font-medium',
                          {
                            'text-danger': profit && profit < 0,
                          },
                          {
                            'text-default-green-700': profit && profit > 0,
                          },
                        )}
                      >
                        {intl.formatNumber(profit, {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </span>
                      <span className="self-end text-xs opacity-50">
                        {t('accountDiary.trades')} {diaryData.tradesExecuted}
                      </span>
                      <span className="self-end text-xs opacity-50">
                        {intl.formatNumber(diaryData.gainPercentage, {
                          style: 'percent',
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  )}
                </div>
              </DetailDrawer>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

export function CalendarWithData({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { data: accountDiary, loading } = useGetAccountTradingDiaryQuery({
    variables: { login: accountId ?? '' },
  });
  const { data: brokerAccount, loading: brokerAccountLoading } =
    useGetBrokerAccountQuery({
      variables: { id: accountId },
      fetchPolicy: 'cache-and-network',
    });

  if (loading || brokerAccountLoading) {
    return (
      <div className="h-40 w-full">
        <Skeleton className="h-full w-full rounded-2xl" />
      </div>
    );
  }

  invariant(
    accountDiary?.accountTradingDiary,
    `[${accountId}] Diary is missing`,
  );
  invariant(
    brokerAccount?.brokerAccount,
    `[${accountId}] Broker account is missing`,
  );

  const sortedDiary = [...(accountDiary?.accountTradingDiary ?? [])].sort(
    (a, b) => {
      const { day: dayA, month: monthA, year: yearA } = a ?? {};
      const { day: dayB, month: monthB, year: yearB } = b ?? {};
      const dateA = new Date(Number(yearA), Number(monthA) - 1, Number(dayA));
      const dateB = new Date(Number(yearB), Number(monthB) - 1, Number(dayB));
      return dateB.getTime() - dateA.getTime();
    },
  );

  // const accountTradingDiary = [
  //   ...Array.from({ length: 31 }, (_, index) => {
  //     const day = index + 1;
  //     if(day < 8||day===20||day===25) {
  //       return  {
  //         __typename: 'MonevisAccountTradingDiary' as const,
  //         id: day,
  //         login: `user${day}`,
  //         year: '2024',
  //         month: '12',
  //         day: day.toString().padStart(2, '0'),
  //         startingBalance: 10000 + day * 100,
  //         startingEquity: 10000 + day * 120,
  //         endingBalance:  10000 + day * 100,
  //         tradesExecuted: 0,
  //         lotsExecuted: day % 3,
  //         endingEquity: 10500 + day * 120,
  //       };
  //  }
  //  else{
  //   return {
  //     __typename: 'MonevisAccountTradingDiary' as const,
  //     id: day,
  //     login: `user${day}`,
  //     year: '2024',
  //     month: '12',
  //     day: day.toString().padStart(2, '0'),
  //     startingBalance: 10000 + day * 100,
  //     startingEquity: 10000 + day * 120,
  //     endingBalance: 10500 + day * 100,
  //     tradesExecuted: day % 5,
  //     lotsExecuted: day % 3,
  //     endingEquity: 10500 + day * 120,
  //   };
  //  }
  //   }),
  // ];

  return (
    <Calendar
      diary={sortedDiary}
      initialBalance={brokerAccount.brokerAccount.initialBalance}
      createdAt={brokerAccount.brokerAccount.createdAt}
    />
  );
}
