import { cn } from '@/utils';
import { type ReactNode } from 'react';
import { Drawer as RootDrawer } from 'vaul';

export function Drawer({
  children,
  disabled,
  trigger,
  contentClassName,
  isOpen,
  handleChange,
}: {
  children: ReactNode;
  disabled?: boolean;
  trigger: ReactNode;
  contentClassName?: string;
  isOpen?: boolean;
  handleChange?: () => void;
}): JSX.Element {
  return (
    <RootDrawer.Root open={isOpen} onOpenChange={handleChange}>
      <RootDrawer.Trigger disabled={disabled}>{trigger}</RootDrawer.Trigger>
      <RootDrawer.Portal>
        <RootDrawer.Overlay className="fixed inset-0 bg-black/40" />
        <RootDrawer.Content
          className={cn(
            'fixed bottom-0 left-0 right-0 mt-24 flex h-[45%] flex-col rounded-t-[10px] bg-default-gray-50 p-5 text-default-gray-950 dark:bg-default-gray-950 dark:text-default-gray-200',
            contentClassName,
          )}
        >
          <div className="mx-auto mb-8 h-1.5 w-12 flex-shrink-0 rounded-full bg-default-gray-200" />
          {children}
        </RootDrawer.Content>
        <RootDrawer.Overlay />
      </RootDrawer.Portal>
    </RootDrawer.Root>
  );
}
