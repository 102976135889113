import { Button } from '@/components/base/button';
import WidgetWithImage from '@/components/base/widget-with-image';
import { useTranslation } from 'react-i18next';

export function Intro(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="mt-8">
      <WidgetWithImage
        img="/images/affiliate-certificates.png"
        header={t('affiliatePage.header')}
        subheader={t('affiliatePage.subheader')}
      >
        <Button
          as="link"
          className="dark:text-white"
          href="https://affiliates.monevis.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('affiliatePage.joinProgram')}
        </Button>
        <Button
          variant="outlined"
          as="link"
          className="dark:text-white"
          href="https://certificates.monevis.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('affiliatePage.showCertificates')}
        </Button>
        <Button
          as="link"
          variant="link"
          className="underline dark:text-white"
          href="https://www.monevis.com/partners"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('affiliatePage.readMore')}
        </Button>
      </WidgetWithImage>
    </div>
  );
}
