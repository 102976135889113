import type { SVGProps } from 'react';

export default function SvgCs(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35}
      height={35}
      fill="none"
      viewBox="0 0 130 80"
      {...props}
    >
      <g filter="url(#cs_svg__a)">
        <g clipPath="url(#cs_svg__b)">
          <rect
            width={117.6}
            height={84}
            x={7.5}
            y={3}
            fill="#F93939"
            rx={9.143}
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M7.5 3h117.6v39.2H7.5z"
            clipRule="evenodd"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="m7.5 3 56 42-56 42z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="cs_svg__b">
          <rect
            width={117.6}
            height={84}
            x={7.5}
            y={3}
            fill="#fff"
            rx={9.143}
          />
        </clipPath>
        <filter
          id="cs_svg__a"
          width={131.314}
          height={97.714}
          x={0.643}
          y={0.714}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4.571} />
          <feGaussianBlur stdDeviation={3.429} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1055_15318"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={0.286} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.32 0" />
          <feBlend
            in2="effect1_dropShadow_1055_15318"
            result="effect2_dropShadow_1055_15318"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1055_15318"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
