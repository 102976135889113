import type { SVGProps } from 'react';

export default function SvgEn(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35}
      height={35}
      fill="none"
      viewBox="0 0 130 80"
      {...props}
    >
      <g filter="url(#en_svg__a)">
        <g clipPath="url(#en_svg__b)">
          <rect
            width={117.6}
            height={84}
            x={7.5}
            y={3}
            fill="#1A47B8"
            rx={9.143}
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M20.01 3H7.5v14l105.022 70H125.1V73z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            d="M11.673 3 125.1 78.798V87h-4.075L7.5 11.123V3z"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M113.9 3h11.2v14S52.355 63.637 18.7 87H7.5V73z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            d="M125.1 3h-3.798L7.5 78.864V87h4.173L125.1 11.185z"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M50.268 3H82.43v25.91h42.67v32.163H82.43V87H50.268V61.073H7.5V28.91h42.768z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M57.016 3h18.568v32.308H125.1v19.384H75.584V87H57.016V54.692H7.5V35.308h49.516z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="en_svg__b">
          <rect
            width={117.6}
            height={84}
            x={7.5}
            y={3}
            fill="#fff"
            rx={9.143}
          />
        </clipPath>
        <filter
          id="en_svg__a"
          width={131.314}
          height={97.714}
          x={0.643}
          y={0.714}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4.571} />
          <feGaussianBlur stdDeviation={3.429} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1055_18420"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={0.286} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.32 0" />
          <feBlend
            in2="effect1_dropShadow_1055_18420"
            result="effect2_dropShadow_1055_18420"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1055_18420"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
