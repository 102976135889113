import type { SVGProps } from 'react';

export default function SvgUr(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35}
      height={35}
      fill="none"
      viewBox="0 0 130 80"
      {...props}
    >
      <g filter="url(#ur_svg__a)">
        <g clipPath="url(#ur_svg__b)">
          <rect
            width={117.6}
            height={84}
            x={7.5}
            y={3}
            fill="#0A6A30"
            rx={9.143}
          />
          <path
            fill="#0A6A30"
            d="M113.9 3H18.7C12.514 3 7.5 8.014 7.5 14.2v61.6c0 6.186 5.014 11.2 11.2 11.2h95.2c6.186 0 11.2-5.014 11.2-11.2V14.2c0-6.186-5.014-11.2-11.2-11.2"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M96.954 36.454c-.37 3.338-1.422 5.746-2.654 5.746s-2.285-2.408-2.654-5.746c-3.338-.37-5.746-1.422-5.746-2.654s2.408-2.285 5.746-2.654c.37-3.338 1.422-5.746 2.654-5.746s2.285 2.408 2.654 5.746c3.338.37 5.746 1.422 5.746 2.654s-2.408 2.285-5.746 2.654M7.5 3h33.6v84H7.5z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M108.132 42.02q.168 1.469.168 2.98c0 13.916-11.284 25.2-25.2 25.2S57.9 58.916 57.9 45s11.284-25.2 25.2-25.2q1.511 0 2.98.168C76.491 21.256 69.1 29.47 69.1 39.4c0 10.825 8.775 19.6 19.6 19.6 9.935 0 18.144-7.392 19.432-16.98"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="ur_svg__b">
          <rect
            width={117.6}
            height={84}
            x={7.5}
            y={3}
            fill="#fff"
            rx={9.143}
          />
        </clipPath>
        <filter
          id="ur_svg__a"
          width={131.314}
          height={97.714}
          x={0.643}
          y={0.714}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4.571} />
          <feGaussianBlur stdDeviation={3.429} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1055_13692"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={0.286} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.32 0" />
          <feBlend
            in2="effect1_dropShadow_1055_13692"
            result="effect2_dropShadow_1055_13692"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1055_13692"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
