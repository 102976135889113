import { Drawer } from '@/components/base/drawer';
import { useScreenSize } from '@/hooks/use-screen-size';
import { cn } from '@/utils';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

export function DetailDrawer({
  children,
  profit,
  lots,
  trades,
  gainPercentage,
}: {
  children: ReactNode;
  profit: number;
  lots: number;
  trades: number;
  gainPercentage: number;
}): JSX.Element {
  const intl = useIntl();
  const { isMedium } = useScreenSize();
  const { t } = useTranslation();

  return (
    <Drawer disabled={isMedium} trigger={children}>
      <>
        <span className="self-center text-lg font-medium">
          {t('accountDiary.detail')}
        </span>
        <div className="divide-y divide-default-gray-400 dark:divide-default-gray-600">
          <div className="flex w-full justify-between py-2">
            <span>{t('accountDiary.profit')}</span>
            <span
              className={cn(
                'text-lg',
                { 'text-danger': profit < 0 },
                { 'text-default-green-700': profit > 0 },
              )}
            >
              {intl.formatNumber(profit, {
                style: 'currency',
                currency: 'USD',
              })}
            </span>
          </div>
          <div className="flex w-full justify-between py-2">
            <span>{t('accountDiary.lots')}</span>
            <span>{lots}</span>
          </div>
          <div className="flex w-full justify-between py-2">
            <span>{t('accountDiary.trades')}</span>
            <span>{trades}</span>
          </div>
          <div className="flex w-full justify-between py-2">
            <span>{t('accountDiary.gainPercentage')}</span>
            <span>
              {intl.formatNumber(gainPercentage, {
                style: 'percent',
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>
      </>
    </Drawer>
  );
}

/*
<Drawer trigger={
children
}>
<>
 <span className="self-center text-lg font-medium">
            {t('accountDiary.detail')}
          </span>
          <div className="divide-y divide-default-gray-400 dark:divide-default-gray-600">
            <div className="flex w-full justify-between py-2">
              <span>{t('accountDiary.profit')}</span>
              <span
                className={cn(
                  'text-lg',
                  { 'text-danger': profit < 0 },
                  { 'text-default-green-700': profit > 0 },
                )}
              >
                {intl.formatNumber(profit, {
                  style: 'currency',
                  currency: 'USD',
                })}
              </span>
            </div>
            <div className="flex w-full justify-between py-2">
              <span>{t('accountDiary.lots')}</span>
              <span>{lots}</span>
            </div>
            <div className="flex w-full justify-between py-2">
              <span>{t('accountDiary.trades')}</span>
              <span>{trades}</span>
            </div>
          </div>
</>
</Drawer>
*/
