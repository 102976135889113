import { PageHeading } from '@/components/base/page-heading';
import { RadioGroup, RadioGroupItem } from '@/components/base/radio-group';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { RadioItemDecoration } from '../components/radio-item-decoration';
import { PriceTiers, getTiers } from '../configuration';

export const getPrice = (balance: number): number => {
  return PriceTiers.find((price) => price.balance === balance)?.price ?? 0;
};

function AccountPriceDecoration({
  price,
  finalPrice,
  oldPrice,
  currency,
}: {
  price: number;
  finalPrice: number;
  oldPrice: number;
  currency: string;
}): JSX.Element {
  const intl = useIntl();
  const finalPricing = finalPrice !== price ? finalPrice : price;

  return (
    <div className="flex items-center">
      {oldPrice > finalPricing && (
        <div className="mr-2 text-xs text-red-400 line-through">
          {intl.formatNumber(Number(oldPrice ?? 0), {
            style: 'currency',
            currency,
            maximumFractionDigits: 2,
          })}
        </div>
      )}
      {intl.formatNumber(finalPricing, {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
      })}
    </div>
  );
}

interface SelectAccountsizeProps {
  initialState?: string;
  onChange: (value: number) => Promise<void>;
  currency: string;
  country?: string;
  hardBalance?: number | string;
  finalPrice?: number;
  oldPrice?: number;
}

export function SelectAccountSize({
  initialState,
  onChange = () => Promise.resolve(),
  currency = 'USD',
  finalPrice,
  oldPrice,
}: SelectAccountsizeProps): JSX.Element {
  const [selectedItem, setSelectedItem] = useState(Number(initialState ?? 0));
  const intl = useIntl();
  const key = initialState;
  const { t } = useTranslation();

  async function handleOnChange(value: number): Promise<void> {
    await onChange(value);
    setSelectedItem(value);
  }

  useEffect(() => {
    setSelectedItem(Number(initialState ?? 0));
  }, [initialState]);
  return (
    <div key={key}>
      <PageHeading
        size="xxlarge"
        subheading={t('selectAccountSize.subheading')}
      >
        {t('selectAccountSize.heading')}
      </PageHeading>

      <RadioGroup
        onValueChange={(v: string) => {
          onChange(Number(v));
        }}
        defaultValue={initialState}
        className="mb-8 mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3"
      >
        {getTiers().map((item) => {
          return (
            <RadioGroupItem
              onClick={async () => {
                await handleOnChange(item);
              }}
              decoration={
                <RadioItemDecoration
                  label={
                    <AccountPriceDecoration
                      price={getPrice(item)}
                      currency={currency}
                      finalPrice={
                        selectedItem === item
                          ? Number(finalPrice)
                          : getPrice(item)
                      }
                      oldPrice={
                        selectedItem === item
                          ? Number(oldPrice ?? 0)
                          : getPrice(item)
                      }
                    />
                  }
                />
              }
              key={item}
              value={String(item)}
              data-testid={String(item)}
            >
              <span className="text-xl font-bold">
                {intl.formatNumber(item, {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0,
                })}
              </span>
            </RadioGroupItem>
          );
        })}
      </RadioGroup>
    </div>
  );
}
