import { PageHeading } from '@/components/base/page-heading';
import {
  faCircleDollar,
  faCircleStar,
  faIdCardClip,
  faServer,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export function SideDescriptionRegister(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="flex h-fit w-full flex-col gap-11 rounded-lg bg-black/70 p-4 text-white">
      <div className=" max-w-[334px]">
        <PageHeading
          size="xxlarge"
          className="dark:text-white"
          subheading={t('sideDescriptionRegister.accessTools')}
        >
          {t('sideDescriptionRegister.innovativeTools')}
        </PageHeading>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div className="max-w-[200px]">
          <FontAwesomeIcon
            fixedWidth
            className="mb-2 h-5 w-5 text-primary"
            icon={faCircleDollar}
          />
          <PageHeading
            className="dark:text-white"
            size="small"
            subheading={t(
              'sideDescriptionRegister.lucrativeProfitSharingSubheading',
            )}
          >
            {t('sideDescriptionRegister.lucrativeProfitSharing')}
          </PageHeading>
        </div>

        <div className="max-w-[200px]">
          <FontAwesomeIcon
            fixedWidth
            className="mb-2 h-5 w-5 text-primary"
            icon={faServer}
          />
          <PageHeading
            className="dark:text-white"
            size="small"
            subheading={t(
              'sideDescriptionRegister.seamlessExecutionSubheading',
            )}
          >
            {t('sideDescriptionRegister.seamlessExecution')}
          </PageHeading>
        </div>

        <div className="max-w-[200px]">
          <FontAwesomeIcon
            fixedWidth
            className="mb-2 h-5 w-5 text-primary"
            icon={faIdCardClip}
          />
          <PageHeading
            className="dark:text-white"
            size="small"
            subheading={t(
              'sideDescriptionRegister.communityAndCollaborationSubheading',
            )}
          >
            {t('sideDescriptionRegister.communityAndCollaboration')}
          </PageHeading>
        </div>

        <div className="max-w-[200px]">
          <FontAwesomeIcon
            fixedWidth
            className="mb-2 h-5 w-5 text-primary"
            icon={faCircleStar}
          />
          <PageHeading
            className="dark:text-white"
            size="small"
            subheading={t('sideDescriptionRegister.flexibleCapitalSubheading')}
          >
            {t('sideDescriptionRegister.flexibleCapital')}
          </PageHeading>
        </div>
      </div>

      <div className=" space-y-4">
        <PageHeading
          size="small"
          subheading={t(
            'sideDescriptionRegister.contactRepresentativeSubheading',
          )}
        />
        <PageHeading className="dark:text-white" size="small">
          {t('sideDescriptionRegister.writeEmail')}
          <span className="text-primary">
            {t('sideDescriptionRegister.infoEmail')}
          </span>
        </PageHeading>
      </div>
    </div>
  );
}
