import { Drawer } from '@/components/base/drawer';
import { Cs, En, Fr, Sk, Ur } from '@/components/icons/flags';
import { EventName, useAnalytics } from '@/context/analytics-context';
import { cn } from '@/utils';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const locales = [
  {
    title: 'English',
    locale: 'en',
    icon: <En />,
  },
  {
    title: 'Slovensky',
    locale: 'sk',
    icon: <Sk />,
  },
  {
    title: 'Česky',
    locale: 'cs',
    icon: <Cs />,
  },
  {
    title: 'اُردُو',
    locale: 'ur',
    icon: <Ur />,
  },
  {
    title: 'French',
    locale: 'fr',
    icon: <Fr />,
  },
];

function LanguageSwitcher(): JSX.Element {
  const { i18n } = useTranslation();
  const { track } = useAnalytics();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleLanguageChange = (lng: string): void => {
    i18n.changeLanguage(lng);
    track(EventName.ChangeLocale, {
      locale: lng,
    });
    setOpenDrawer(false);
  };

  return (
    <Drawer
      isOpen={openDrawer}
      handleChange={() => {
        setOpenDrawer(!openDrawer);
      }}
      trigger={
        <div className="flex items-center gap-2 px-6 py-[10px]">
          <span className="relative flex">
            {locales.find((locale) => locale.locale === i18n.language)?.icon}
          </span>
          <FontAwesomeIcon
            className="h-4 w-4 text-default-gray-950 dark:text-white"
            icon={faChevronDown}
          />
        </div>
      }
      contentClassName="h-[50%] z-50 dark:bg-dark"
    >
      <div className="flex w-full flex-col gap-2 overflow-y-auto">
        {locales.map((locale) => (
          <button
            key={locale.locale}
            type="button"
            className="rounded-md"
            onClick={() => {
              handleLanguageChange(locale.locale);
            }}
            tabIndex={0}
            aria-label={locale.title}
          >
            <div
              className={cn('flex items-center gap-2 px-2 py-1', {
                'rounded-md bg-default-gray-200 dark:bg-default-gray-900':
                  i18n.language === locale.locale,
              })}
              aria-hidden="true"
            >
              {locale.icon}
              {locale.title}
            </div>
          </button>
        ))}
      </div>
    </Drawer>
  );
}

export default LanguageSwitcher;
